const randomHex = require('random-hex-color')
const chroma = require('chroma-js')
const sample = require('lodash.sample')
const adaptiveGradient = require('adaptive-gradient').default
const { randomInt, toPx } = require('../util')
const randomBoxShadow = require('./box-shadow')

const textAlign = () => sample(['center', 'right', 'justify', 'left'])

const textDecorationStyle = () =>
  sample(['solid', 'double', 'dotted', 'dashed', 'wavy'])

const textDecorationLine = () =>
  sample([
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'underline',
    'overline',
    'line-through',
    'blink'
  ])

const blendMode = () =>
  sample([
    'normal',
    'multiply',
    'screen',
    'overlay',
    'darken',
    'lighten',
    'color-dodge',
    'color-burn',
    'hard-light',
    'soft-light',
    'difference',
    'exclusion',
    'hue',
    'saturation',
    'color',
    'luminosity'
  ])

const toTextShadow = textShadow =>
  [
    toPx(textShadow.textShadowOffsetX),
    toPx(textShadow.textShadowOffsetY),
    toPx(textShadow.textShadowBlur),
    textShadow.textShadowColor
  ].join(' ')

const generateTextShadow = color => {
  const shadowBool = randomInt(0, 1)
  var boxShadow = 'none'
  if (shadowBool > 0) {
    boxShadow = randomBoxShadow()
  }

  color = color || randomHex()

  const radiusBool = randomInt(0, 10)

  const size = randomInt(32, 80)
  const padding = randomInt(0, 8)

  const fontWeight = sample([
    100,
    200,
    300,
    400,
    400,
    400,
    500,
    500,
    500,
    600,
    600,
    600,
    700,
    700,
    700,
    800,
    800,
    800,
    900,
    900,
    900,
    900,
    900
  ])
  const fontSize = randomInt(64, 128)
  const textShadowOffsetX = randomInt(1, 16)
  const textShadowOffsetY = randomInt(1, 16)
  const textShadowBlur = randomInt(0, 16)

  const boxSizing = sample(['border-box', 'content-box'])

  const textShadow = {
    fontFamily: 'system-ui, sans-serif',
    boxSizing: boxSizing,
    fontWeight: fontWeight,
    fontSize: fontSize,
    color: color, // set color for wrapper border
    backgroundColor: randomHex(), // set background of wrapper color
    //startColorHinting,
    //endColor: '#' + endColor,
    //endColorHinting,
    boxShadow: boxShadow,
    text: 'Rad Sparkle',
    textAlign: textAlign(),
    textShadowOffsetX: textShadowOffsetX,
    textShadowOffsetY: textShadowOffsetY,
    textShadowBlur: textShadowBlur,
    textShadowColor: '#000',
    lineHeight: 100,
    letterSpacing: 0,
    textTransform: 'uppercase',
    fontStyle: 'normal',
    textDecorationColor: '#000',
    textDecorationLine: 'none',
    textDecorationStyle: textDecorationStyle()
  }

  return {
    ...textShadow
  }
}

generateTextShadow.toTextShadow = toTextShadow

module.exports = generateTextShadow
