import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { Grid, ArrowRight, Heart, BookOpen } from 'react-feather'

import H5 from '../elements/H5'
import Textarea from '../elements/Textarea'
import Input from '../elements/Input'
import Span from '../elements/Span'
import Flex from '../components/Flex'
import SectionBorder from '../components/SectionBorder'
import Lock from '../components/Lock'
import Layout from '../components/VoteLayout'
import Label from '../components/Label'
import Color from '../components/controls/Color'
import Hidden from '../components/hidden'

import VoteButton from '../components/vote-button'
import SidebarHeading from '../components/heading-sidebar'

import randomTextShadow from '../lib/generate/text-shadow'
import { db } from '../lib/client'
import { UP_KEYCODE, RIGHT_KEYCODE } from '../lib/constants'

const Main = ({ textShadow }) => (
  <div
    css={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '16rem',
      height: '100%',
      maxHeight: '100vh',
      paddingLeft: 32,
      paddingRight: 32,
      overflowX: 'hidden',
      backgroundColor: textShadow.backgroundColor
    }}
  >
    <div
      children={textShadow.text}
      css={{
        fontFamily: textShadow.fontFamily,
        fontWeight: textShadow.fontWeight,
        fontSize: textShadow.fontSize + 'px',
        fontStyle: textShadow.fontStyle,
        textTransform: textShadow.textTransform,
        textShadow: randomTextShadow.toTextShadow(textShadow),
        color: textShadow.color,
        letterSpacing: textShadow.letterSpacing / 100 + 'em',
        textDecorationColor: textShadow.textDecorationColor,
        textDecorationLine: textShadow.textDecorationLine,
        textDecorationStyle: textShadow.textDecorationStyle,
        lineHeight: textShadow.lineHeight / 100
      }}
    />
  </div>
)

const GridItem = ({ textShadow, ...props }) => (
  <div
    css={{
      width: '25%',
      paddingLeft: '8px',
      paddingRight: '8px',
      paddingTop: '16px',
      height: '24vh'
    }}
    {...props}
  >
    <a
      css={{
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
        background: textShadow.backgroundColor
      }}
    >
      <div
        children="Xy"
        css={{
          fontWeight: textShadow.fontWeight,
          fontSize: textShadow.fontSize + 'px',
          fontStyle: textShadow.fontStyle,
          textShadow: randomTextShadow.toTextShadow(textShadow),
          color: textShadow.color,
          letterSpacing: textShadow.letterSpacing / 100 + 'em',
          textDecorationColor: textShadow.textDecorationColor,
          textDecorationLine: textShadow.textDecorationLine,
          textDecorationStyle: textShadow.textDecorationStyle,
          lineHeight: textShadow.lineHeight / 100
        }}
      />
    </a>
  </div>
)

const GridView = ({ pins, onSelect }) => (
  <Flex
    css={{
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      paddingTop: '32px',
      paddingBottom: '32px',
      paddingLeft: '8px',
      paddingRight: '8px'
    }}
  >
    {Array(16)
      .fill(0)
      .map((_, i) => {
        const baseTextShadow = randomTextShadow()
        const textShadowWithPins = { ...baseTextShadow, ...pins }
        const textShadow = {
          ...textShadowWithPins
        }

        return (
          <GridItem
            key={i}
            onClick={() => onSelect(textShadow)}
            textShadow={textShadow}
          />
        )
      })}
  </Flex>
)

const Sidebar = ({ onChange, value, pins, onPin }) => {
  const changeValue = key => e => {
    onChange(key, e.target.value)
  }

  return (
    <>
      <SidebarHeading>Text Shadow</SidebarHeading>

      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.text}
          onClick={() => onPin('text', value.text)}
        />
        <Label>Text</Label>
        <Input
          width={1}
          value={value.text}
          type="text"
          onChange={changeValue('text')}
        />
      </Flex>
      <Flex>
        <Lock
          bg="transparent"
          active={pins.textShadowOffsetX}
          onClick={() => onPin('textShadowOffsetX', value.textShadowOffsetX)}
        />
        <Label>Offset X</Label>
        <Input
          min={-200}
          max={200}
          steps={1}
          width={1}
          mr={2}
          value={Number(value.textShadowOffsetX)}
          type="range"
          onChange={changeValue('textShadowOffsetX')}
        />
        <Span
          width="80px"
          textAlign="right"
          ml="auto"
          fontSize={0}
          children={value.textShadowOffsetX}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.textShadowOffsetY}
          onClick={() => onPin('textShadowOffsetY', value.textShadowOffsetY)}
        />
        <Label>Offset Y</Label>
        <Input
          min={-200}
          max={200}
          steps={1}
          width={1}
          mr={2}
          value={Number(value.textShadowOffsetY)}
          type="range"
          onChange={changeValue('textShadowOffsetY')}
        />
        <Span
          width="80px"
          textAlign="right"
          ml="auto"
          fontSize={0}
          children={value.textShadowOffsetY}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.textShadowBlur}
          onClick={() => onPin('textShadowBlur', value.textShadowBlur)}
        />
        <Label>Blur</Label>
        <Input
          min={0}
          max={200}
          steps={1}
          width={1}
          mr={2}
          value={Number(value.textShadowBlur)}
          type="range"
          onChange={changeValue('textShadowBlur')}
        />
        <Span
          width="80px"
          textAlign="right"
          ml="auto"
          fontSize={0}
          children={value.textShadowBlur}
        />
      </Flex>
      <Color
        value={value.textShadowColor}
        onChange={changeValue('textShadowColor')}
        active={pins.textShadowColor}
        onLock={() => onPin('textShadowColor', value.textShadowColor)}
        width={128}
      />
      <SectionBorder my={3} />
      <H5 fontSize={0} mb={2}>
        Canvas
      </H5>
      <Color
        label="Color"
        value={value.color}
        onChange={changeValue('color')}
        active={pins.color}
        onLock={() => onPin('color', value.color)}
        width={128}
      />
      <Color
        label="Background"
        value={value.backgroundColor}
        onChange={changeValue('backgroundColor')}
        active={pins.backgroundColor}
        onLock={() => onPin('backgroundColor', value.backgroundColor)}
        width={128}
      />
      <H5 fontSize={0} mb={2}>
        Typography
      </H5>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.fontFamily}
          onClick={() => onPin('fontFamily', value.fontFamily)}
        />
        <Label>Font Family</Label>
        <Input
          width={1}
          value={value.fontFamily}
          type="text"
          onChange={changeValue('fontFamily')}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.fontSize}
          onClick={() => onPin('fontSize', value.fontSize)}
        />
        <Label>Font Size</Label>
        <Input
          min={0}
          max={256}
          steps={1}
          width={1}
          mr={2}
          value={Number(value.fontSize)}
          type="range"
          onChange={changeValue('fontSize')}
        />
        <Span
          width="80px"
          textAlign="right"
          ml="auto"
          fontSize={0}
          children={value.fontSize}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.fontWeight}
          onClick={() => onPin('fontWeight', value.fontWeight)}
        />
        <Label>Font Weight</Label>
        <select value={value.fontWeight} onChange={changeValue('fontWeight')}>
          <option>100</option>
          <option>200</option>
          <option>300</option>
          <option>400</option>
          <option>500</option>
          <option>600</option>
          <option>700</option>
          <option>800</option>
          <option>900</option>
        </select>
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.textTransform}
          onClick={() => onPin('textTransform', value.textTransform)}
        />
        <Label>Text Transform</Label>
        <select
          value={value.textTransform}
          onChange={changeValue('textTransform')}
        >
          <option>capitalize</option>
          <option>uppercase</option>
          <option>lowercase</option>
          <option>none</option>
        </select>
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.textAlign}
          onClick={() => onPin('textAlign', value.textAlign)}
        />
        <Label>Text Align</Label>
        <select value={value.textAlign} onChange={changeValue('textAlign')}>
          <option>left</option>
          <option>right</option>
          <option>center</option>
          <option>justify</option>
        </select>
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.fontStyle}
          onClick={() => onPin('fontStyle', value.fontStyle)}
        />
        <Label>Font Style</Label>
        <select value={value.fontStyle} onChange={changeValue('fontStyle')}>
          <option>normal</option>
          <option>italic</option>
          <option>oblique</option>
        </select>
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.lineHeight}
          onClick={() => onPin('lineHeight', value.lineHeight)}
        />
        <Label>Line Height</Label>
        <Input
          min={0}
          max={400}
          steps={1}
          width={1}
          mr={2}
          value={Number(value.lineHeight)}
          type="range"
          onChange={changeValue('lineHeight')}
        />
        <Span
          width="80px"
          textAlign="right"
          ml="auto"
          lineHeight={0}
          children={value.lineHeight / 100}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.letterSpacing}
          onClick={() => onPin('letterSpacing', value.letterSpacing)}
        />
        <Label>Letter Spacing</Label>
        <Input
          min={-200}
          max={300}
          steps={1}
          width={1}
          mr={2}
          value={Number(value.letterSpacing)}
          type="range"
          onChange={changeValue('letterSpacing')}
        />
        <Span
          width="80px"
          textAlign="right"
          ml="auto"
          letterSpacing={0}
          children={value.letterSpacing / 100}
        />
      </Flex>
      <H5 mb={2}>Text Decoration</H5>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.textDecorationLine}
          onClick={() => onPin('textDecorationLine', value.textDecorationLine)}
        />
        <Label>Line</Label>
        <select
          value={value.textDecorationLine}
          onChange={changeValue('textDecorationLine')}
        >
          <option>none</option>
          <option>underline</option>
          <option>overline</option>
          <option>line-through</option>
          <option>blink</option>
        </select>
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.textDecorationStyle}
          onClick={() =>
            onPin('textDecorationStyle', value.textDecorationStyle)
          }
        />
        <Label>Style</Label>
        <select
          value={value.textDecorationStyle}
          onChange={changeValue('textDecorationStyle')}
        >
          <option>solid</option>
          <option>double</option>
          <option>dotted</option>
          <option>dashed</option>
          <option>wavy</option>
        </select>
      </Flex>
      <Color
        value={value.textDecorationColor}
        onChange={changeValue('textDecorationColor')}
        active={pins.textDecorationColor}
        onLock={() => onPin('textDecorationColor', value.textDecorationColor)}
        width={128}
      />
      <SectionBorder my={4} />
      <H5 mb={1}>Css</H5>
      <Textarea
        bg="transparent"
        height={64}
        width={1}
        border="1px solid rgba(0,0,0,.25)"
        p={2}
        readOnly
        value={`.shadow {  text-shadow: '${randomTextShadow.toTextShadow(
          value
        )}' }`}
      />
      <H5 mb={1}>Js</H5>
      <Textarea
        bg="transparent"
        height={128}
        width={1}
        border="1px solid rgba(0,0,0,.25)"
        p={2}
        readOnly
        value={
          "import styled from '@emotion/styled'\n\n" +
          "const TextShadow = styled('p')({\n" +
          `  textShadow: '${randomTextShadow.toTextShadow(value)}'` +
          '\n})\n'
        }
      />
    </>
  )
}

export default ({ initialTextShadow, initialPins }) => {
  let votes = []
  try {
    votes = JSON.parse(localStorage.getItem('textShadowVotes'))
  } catch (e) {}

  const [textShadow, setTextShadow] = useState(
    initialTextShadow || randomTextShadow()
  )
  const [pins, setPins] = useState(initialPins || {})
  const [gridView, setGridView] = useState(false)

  const textShadowWithPins = { ...textShadow, ...pins }
  const fullTextShadow = {
    ...textShadowWithPins
  }

  const upvote = async () => {
    if (gridView) {
      return
    }

    const { id } = await db
      .collection('components')
      .doc('textShadows')
      .collection('votes')
      .add({
        up: true,
        data: fullTextShadow,
        metadata: { pins }
      })

    localStorage.setItem(
      'textShadowVotes',
      JSON.stringify([{ id }, ...votes].slice(0, 100).filter(Boolean))
    )

    setTextShadow(randomTextShadow())
  }
  const skip = () => {
    setTextShadow(randomTextShadow())
  }
  const toggleGridView = () => {
    setGridView(!gridView)
  }

  const handleVoteKey = e => {
    if (e.keyCode === UP_KEYCODE || e.keyCode === RIGHT_KEYCODE) {
      e.preventDefault && e.preventDefault()
    } else {
      return
    }

    if (e.keyCode === UP_KEYCODE) {
      upvote()
    } else if (e.keyCode === RIGHT_KEYCODE) {
      skip()
    }
  }

  const handleGridSelection = textShadow => {
    setTextShadow(textShadow)
    setGridView(false)
  }

  useEffect(() => {
    window.addEventListener('keydown', handleVoteKey)

    return () => window.removeEventListener('keydown', handleVoteKey)
  })

  return (
    <Layout
      main={
        gridView ? (
          <GridView pins={pins} onSelect={handleGridSelection} />
        ) : (
          <Main textShadow={fullTextShadow} />
        )
      }
      sidebar={
        <Sidebar
          value={fullTextShadow}
          pins={pins}
          onChange={(key, value) => {
            setTextShadow({
              ...textShadow,
              [key]: value
            })

            setPins({
              ...pins,
              [key]: value
            })
          }}
          onPin={(key, value) => {
            if (!pins[key]) {
              setPins({
                ...pins,
                [key]: value
              })
            } else {
              const newPins = { ...pins }
              delete newPins[key]
              setPins(newPins)
            }
          }}
        />
      }
      footer={
        <>
          <div
            css={{
              color: 'black',
              textAlign: 'center',
              padding: 20,
              display: 'none', // 'flex',
              width: '100%',
              alignItems: 'center',
              borderBottom: '1px solid rgba(0,0,0,.1)'
            }}
          >
            <Link
              to="/textShadow/votes"
              css={{
                color: 'black',
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
                textDecoration: 'none'
              }}
            >
              <BookOpen size={20} />
              <span css={{ paddingLeft: 10 }}>Previous likes</span>
            </Link>
          </div>
          <Flex>
            <VoteButton
              disabled={gridView}
              css={{
                '&:disabled': {
                  cursor: 'not-allowed',
                  opacity: 0.4
                }
              }}
              onClick={upvote}
            >
              <Heart size={20} />
              <Hidden>Save</Hidden>
            </VoteButton>
            <VoteButton onClick={skip}>
              <ArrowRight size={20} />
              <Hidden>Next</Hidden>
            </VoteButton>
            <VoteButton onClick={toggleGridView}>
              <Grid size={20} />
              <Hidden>Grid view</Hidden>
            </VoteButton>
          </Flex>
        </>
      }
    />
  )
}
